
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs, watch} from "vue"
import type {UnwrapRef} from "vue"
import ApiArticle from "@/request/apis/api_article"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import {IAdminRole, permissionTypes} from "@/models/menu_model";
import ApiCourse from "@/request/apis/api_course";
import {AuthMenu} from "@/models/menu_model";
import ApiAdmin from "@/request/apis/api_admin";

interface IState {
  loading: boolean;
  allMenu: AuthMenu[],
  selectMenu: number[],
  expandedKeys: number[],
  selectAll:number[]
}

// 新增编辑文章
export default defineComponent({
  name: "AdminPermissionSet",
  components: {UploadComponent},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any;
    const formRef = ref()
    const editorComponentRef = ref()
    const formState: UnwrapRef<IAdminRole> = reactive({
      name: "",
      desc: "",
      auth_ids: [],
      status: 0
    })
    const rules = {
      name: [{required: true, message: "请输入角色名称"},],
      auth_ids: [{required: true, message: "请选择角色权限", type:"array"},],
    }

    const selectedKeys = ref<string[]>([]);

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)}
            // if(state.selectAll.length > 0){
            //    params.auth_ids = state.selectAll
            // }
            params.auth_ids = params.auth_ids.checked
            
            if (editId) {
              params.id = editId
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const state: IState = reactive({
      loading: false,
      selectMenu: [],
      allMenu: [],
      expandedKeys: [],
      selectAll:[]
    })

    // 获取全部菜单
    const getAllMenu = async () => {
      state.loading = true
      const res = await ApiAdmin.getAllMenus({page: 1, limit: 1000});
      state.loading = false
      if (res && res.error_code === 0) {
        state.allMenu = res.data?.lists || [];
        state.allMenu = exchangeListData(state.allMenu);
        state.expandedKeys = state.allMenu?.map(item => {return item.id}) as number[];
      }
    };

    const onSelect = (checkedKeys:any) => {
      console.log(checkedKeys)
      // state.selectAll = selectedKeys.concat(info.halfCheckedKeys);
      // console.log(state.selectAll);
      // const obj: {[key: number]: number;} = {};
      // let newIds:number[] = [];
      // formState.auth_ids.concat(selectedKeys).map((item:number) => {
      //   if (!obj[item]) {
      //     obj[item] = 0;
      //     newIds.push(item);
      //     return item;
      //   }
      //   obj[item] ++;
      //   if (obj[item] > 0) {
      //     newIds.splice(newIds.indexOf(item), 1);
      //     console.log("wtf")
      //   }
      //   return item;
      // });
      // console.log(newIds, selectedKeys)
      // formState.auth_ids = newIds;
    };

    // 转换筛选list数据
    const exchangeListData = (list:AuthMenu[]) => {
      list.map(item => {
        item.sub = item.sub && item.sub.length ? item.sub : undefined;
        if (item.sub && item.sub.length) {
          item.sub = exchangeListData(item.sub);
        }
      })
      return list;
    }

    // 获取详情
    const getDetail = async () => {
      const {id, data} = route.query
      //IArticleDetail
      if (id && data) {
        editId = id
        let datas = JSON.parse(data as string) as IAdminRole;
        formState.id = parseInt(id as string);
        formState.name = datas.name;
        formState.desc = datas.desc;
        formState.auth_ids = datas.auth_ids;
        console.log("formState.auth_ids", formState.auth_ids)
      }
    }

    // 新增
    const onAdd = async (params: any) => {
      state.loading = true
      console.log(params)
      const res = await ApiAdmin.addRole(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiAdmin.updateRole(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    const goBack = () => {
      router.back()
    }

    onMounted(() => {
      getAllMenu();
      getDetail()
    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      goBack,
      permissionTypes,
      ...toRefs(state),
      editorComponentRef,
      onSelect,
      selectedKeys
    }
  },
})
